import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layouts/Layout"
import {
  InteriorBanner,
  ContactSection,
  ImageGrid,
} from "../components/elements"
import { SEO, Section, Color } from "../components/utilities"

import banner from "../images/pool2.jpg"
import pic1 from "../images/inground-pool.jpg"
import pic2 from "../images/process-vinyl.jpg"
import pic3 from "../images/pool-pic.jpg"

const imageArr = [
  {
    image: pic1,
    altTag:
      "Backyard swimming pool: custom inground pool with large patio and landscaping.",
  },
  {
    image: pic2,
    altTag:
      "Pool construction process: stages of building a custom swimming pool with curved steps.",
  },
  {
    image: pic3,
    altTag: "Backyard swimming pool with diving board: custom inground pool.",
  },
]

const PoolBuildPage = () => (
  <Layout>
    <SEO
      title="Custom Pool Builds in Toledo, Ohio area"
      description="Hunt Pools offers affordable pool construction options for your uniquestyle and budget. Expert quality construction for Perrysburg and Sylvania Ohio"
    />
    <InteriorBanner image={banner}>
      <h1>Pool Build</h1>
    </InteriorBanner>
    <Section>
      <h2>Transform Your Backyard with a Custom Pool Build</h2>
      <p>
        Are you dreaming of turning your backyard into a luxurious retreat? Look
        no further than Hunt Pools & Service for expert pool construction
        services in Perrysburg, Monclova, Maumee, Sylvania, Ottawa Hills, and
        the surrounding Toledo, Ohio area. Whether you envision a simple,
        elegant design or a more elaborate oasis, we have the expertise and
        options to bring your vision to life.
      </p>
      <br />
      <br />
      <h2>Tailored Designs for Every Taste and Budget</h2>
      <p>
        At Hunt Pools & Service, we understand that every homeowner has unique
        preferences and budgetary considerations. That's why we offer a wide
        range of design options to suit your style and financial parameters.
        From sleek geometric shapes to free-flowing natural designs, our team
        will work closely with you to create a pool that reflects your
        personality and enhances your outdoor space.
      </p>
      <br />
      <br />
      <h2>Seamless Process from Design to Completion</h2>
      <p>
        Our dedicated team will guide you through every step of the pool
        building process, ensuring a smooth and stress-free experience. We start
        with an in-depth consultation to understand your vision and preferences.
        From there, our designers will create a customized plan that maximizes
        both aesthetics and functionality. Throughout the construction phase, we
        maintain open communication and attention to detail to ensure your
        complete satisfaction.
      </p>
      <br />
      <br />
      <h2>Enhance Your Experience with Custom Features</h2>
      <p>
        Make your pool truly one-of-a-kind with our wide range of customizable
        features. From convenient WiFi controls for easy operation to luxurious
        amenities like custom seating, tanning ledges, waterfalls, and lighting,
        the options are endless. Our goal is to create a personalized oasis
        where you can relax, entertain, and create lasting memories with family
        and friends.
      </p>
      <br />
      <br />
      <h2>Quality Craftsmanship You Can Trust</h2>
      <p>
        At Hunt Pools & Service, we take pride in our commitment to excellence.
        That's why we partner with only the highest quality subcontractors who
        share our dedication to craftsmanship and customer satisfaction. With
        our meticulous attention to detail and superior materials, you can trust
        that your new pool will provide years of enjoyment and value.
      </p>
      <br />
      <br />
      <h2>Affordable Luxury Made Possible</h2>
      <p>
        Luxury doesn't have to come with a hefty price tag. Our affordable pool
        construction services make it easy to turn your backyard dreams into
        reality.{" "}
        <Link to="/contact-us/" style={{ color: Color.primary }}>
          Contact us today
        </Link>{" "}
        to learn more about our design options, pool artistry, and budget
        ranges. Our team is here to help you create the outdoor oasis you've
        always wanted. Schedule your consultation with Hunt Pools & Service
        today and start building your dream pool!
      </p>
    </Section>
    <ImageGrid images={imageArr} />
    <ContactSection />
  </Layout>
)

export default PoolBuildPage
